import { UnstyledButton, createStyles } from '@mantine/core';
import { Avatar, Menu, Text } from '@akin/ui-lib';
import { Trans } from '@lingui/macro';
import { getUserImageUrl } from '@akin/core-lib/utils/user';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getSpaceProfileImageURL } from '@akin/core-lib/utils/spaceProfile';
import PAGE_ROUTES from '../../../../constants/pageRoutes';
import useAuth from '../../../../hooks/useAuth';
import useUser from '../../../../hooks/useUser';
import TopMenuList from './TopMenuList';
import FooterMenuList from './FooterMenuList';
import SubMenu from './SubMenu';
import { MENU_FOOTER_LIST, MENU_HEADER_LIST } from '../../../../constants/menu';
import useSpace from '../../../../hooks/useSpace';
import DEV_SUPER_ADMIN from '../../../../constants/devSuperAdmins';

const useStyles = createStyles((theme) => ({
  sidebarContainer: {
    display: 'flex',
    height: '100%',
  },
  navContainer: {
    width: 75,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.black,
    ...theme.other.hideScrollBar,
    [theme.fn.smallerThan('xs')]: {
      height: '100%',
      width: '100%',
      backgroundColor: 'unset',
    },
  },
  spacer: {
    flex: '1 1',
  },
  profile: {
    width: '100%',
    borderRadius: 10,
    cursor: 'pointer',
    boxShadow: theme.other.boxShadow.cards,
    color: theme.white,
    '& .mantine-Avatar-root': {
      margin: '0 auto',
    },
  },
  profileDropdown: {
    boxShadow: theme.other.boxShadow.cards,
    [theme.fn.smallerThan('xs')]: {
      '&.mantine-Menu-dropdown': {
        width: '90%!important',
      },
    },
  },
  avatarBtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '6px',
    width: '100%',
  },
  headerAvatar: {
    margin: '5px 0px',
    '&.devSuperAdmin': {
      backgroundColor: '#A93A3A',
    },
  },
  footerAvatar: {
    marginBottom: 10,
  },
}));

const MENU_LIST = MENU_HEADER_LIST.concat(MENU_FOOTER_LIST);

function getCurrentRouteInfo(currentRoute) {
  const data = { hasSubMenu: false, subMenu: null };

  if (!currentRoute) return data;

  const menuInfo = MENU_LIST.find((menu) => {
    if (
      currentRoute !== PAGE_ROUTES.home &&
      menu.baseRoute === PAGE_ROUTES.home
    )
      return;
    return currentRoute.includes(menu.baseRoute);
  });

  data.hasSubMenu = menuInfo?.hasSubMenu;
  data.subMenu = menuInfo?.hasSubMenu && [...menuInfo.subMenu];

  return data;
}

function UserAvatar({
  src = '',
  title = '',
  onClick,
  className,
  lineClamp = 1,
}) {
  const { classes, cx } = useStyles();
  return (
    <UnstyledButton
      onClick={onClick}
      className={cx(classes.avatarBtn, className)}
    >
      <Avatar src={src} radius="xl" size={30} />
      <Text
        size={9}
        ta="center"
        lh="11px"
        lineClamp={lineClamp}
        transform="capitalize"
        color="white"
        weight={600}
        mt={3}
      >
        {title}
      </Text>
    </UnstyledButton>
  );
}

export default function SidebarMenu({
  isMobileScreen,
  openPropertySettingsModal,
}) {
  const { cx, classes } = useStyles();
  const router = useRouter();
  const auth = useAuth();
  const { user } = useUser();
  const { currentSpace } = useSpace();
  const userImage = getUserImageUrl(user);

  // DEV_SUPER_ADMIN are list of super admin email ids
  // use-case: A DEV_SUPER_ADMIN will have header background color set to red
  const isDevSuperAdmin = DEV_SUPER_ADMIN.includes(user?.email);

  const currentRouteInfo = useMemo(
    () => getCurrentRouteInfo(router.asPath),
    [router.asPath]
  );

  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.navContainer}>
        {/* ================== SPACE PROFILE AVATAR ===================== */}
        {!isMobileScreen && (
          <UserAvatar
            title={currentSpace?.name}
            src={getSpaceProfileImageURL(currentSpace)}
            className={cx(classes.headerAvatar, {
              devSuperAdmin: isDevSuperAdmin,
            })}
            onClick={openPropertySettingsModal}
            lineClamp={2}
          />
        )}

        {/* ================== SIDEBAR TOP MENU LIST  ===================== */}
        <div className={classes.topMenu}>
          <TopMenuList isMobileScreen={isMobileScreen} />
        </div>

        <div className={classes.spacer} />

        {/* ================== SIDEBAR FOOTER MENU LIST  ===================== */}
        <FooterMenuList
          isMobileScreen={isMobileScreen}
          onOpenSettings={openPropertySettingsModal}
        />

        {/* ================== USER POPUP MENU ===================== */}
        <Menu
          position="top"
          offset={0}
          width={200}
          className={cx(classes.profile, { hello: true })}
        >
          <Menu.Target>
            <div>
              <UserAvatar
                src={userImage}
                title={user.full_name}
                className={classes.footerAvatar}
              />
            </div>
          </Menu.Target>

          <Menu.Dropdown className={classes.profileDropdown}>
            <Menu.Item onClick={() => router.push(PAGE_ROUTES.profile)}>
              <Trans>Edit your profile</Trans>
            </Menu.Item>
            <Menu.Item onClick={auth.logout}>
              <Trans>Logout</Trans>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>

      {/* ================== DESKTOP SUBMENU ===================== */}
      {!isMobileScreen && currentRouteInfo?.hasSubMenu && (
        <SubMenu listData={currentRouteInfo?.subMenu} />
      )}
    </div>
  );
}
