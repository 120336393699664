import { Text } from '@akin/ui-lib';
import { useState } from 'react';
import ChevronDown from '@akin/ui-lib/svg/ChevronDown';
import { createStyles } from '@mantine/core';
import { useRouter } from 'next/router';

const useStyles = createStyles((theme) => ({
  navItem: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: 12,
    lineHeight: '1.5',
    cursor: 'pointer',
    textDecoration: 'none',
    [theme.fn.smallerThan('xs')]: {
      justifyContent: 'space-between',
      backgroundColor: 'transparent',
      color: '#6D6B6B',
      '&:hover, &.selected': {
        backgroundColor: '#212121',
        color: theme.white,
      },
    },
  },
  label: {
    whiteSpace: 'pre-wrap',
  },
  subMenu: {
    padding: 10,
    paddingLeft: '1.8rem',
    lineHeight: '1.5',
    cursor: 'pointer',
    '&:hover, &.selected': {
      backgroundColor: '#212121',
      color: theme.white,
    },
  },
  dropdownIcon: {
    cursor: 'pointer',
    '&.active': {
      transform: 'rotate(-180deg)',
    },
  },
}));

export default function NavSubMenuItem({ title, subMenu, hidden = false }) {
  const { cx, classes } = useStyles();
  const router = useRouter();
  const [displaySubMenu, setDisplaySubMenu] = useState();

  const onClickHandler = () => {
    setDisplaySubMenu((prevState) => !prevState);
  };

  const onChangeRoute = (sMenu) => {
    router.push({
      pathname: sMenu.route,
      ...(sMenu?.query?.tabId && {
        query: {
          tabId: sMenu.query.tabId,
        },
      }),
    });
  };

  if (hidden) return null;

  return (
    <>
      <div
        className={classes.navItem}
        onClick={onClickHandler}
        aria-hidden="true"
      >
        <Text size="sm" weight={600} className={classes.label}>
          {title}
        </Text>

        <ChevronDown
          className={classes.dropdownIcon}
          width={20}
          height={20}
          stroke="#6D6B6B"
          strokeWidth="1"
        />
      </div>

      {displaySubMenu && (
        <>
          {subMenu.map((menu) => {
            if (menu.hidden) return null;

            return (
              <div
                key={`sub_menu_${menu.id}`}
                className={cx(classes.subMenu, {
                  selected: router.pathname === menu.route,
                })}
                onClick={() => onChangeRoute(menu)}
                aria-hidden="true"
              >
                <Text
                  size="sm"
                  weight={600}
                  color="#6D6B6B"
                  className={classes.label}
                >
                  {menu.name}
                </Text>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
