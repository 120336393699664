import { AkinModal as Modal, Text } from '@akin/ui-lib';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/core';
import { t } from '@lingui/macro';
import PropertySelect from './property-select';

const useStyles = createStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.fn.smallerThan('xs')]: {
      alignItems: 'center',
    },
  },
}));

export default function PropertySettingsModal({ opened, onClose }) {
  const isMobileScreen = useMediaQuery('(max-width: 830px)');

  const { classes } = useStyles();
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      fullScreen={isMobileScreen}
      showDefaultHeader={false}
      size={750}
    >
      <Modal.Header className={classes.header}>
        <Text fz={18} weight={500}>
          {t`Select property`}
        </Text>
        <Modal.CloseButton onClose={onClose} />
      </Modal.Header>

      <PropertySelect closeModal={onClose} />
    </Modal>
  );
}
