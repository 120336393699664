import { Trans, t } from '@lingui/macro';
import ICONS from '@akin/core-lib/icons';
import PAGE_ROUTES from './pageRoutes';

export const MENU_HEADER_LIST = [
  {
    id: 'home',
    name: <Trans>Home</Trans>,
    route: PAGE_ROUTES.home,
    baseRoute: PAGE_ROUTES.home,
    query: {},
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: false,
    icon: ICONS.homeHeart,
  },
  {
    id: 'inbox',
    name: <Trans>Inbox</Trans>,
    route: PAGE_ROUTES.inbox,
    baseRoute: PAGE_ROUTES.inbox,
    query: {},
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: false,
    icon: ICONS.inbox,
  },
  {
    id: 'ai-skills',
    name: <Trans>Skills</Trans>,
    route: PAGE_ROUTES.aiSkillPersonality,
    baseRoute: PAGE_ROUTES.aiSkill,
    query: {},
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: false,
    hasSubMenu: true,
    icon: ICONS.skills,
    subMenu: [
      {
        id: 'skills/personality',
        name: <Trans>Personality</Trans>,
        description: t`Define AI's character`,
        route: PAGE_ROUTES.aiSkillPersonality,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'skills/housekeeping',
        name: <Trans>Housekeeping</Trans>,
        description: t`Connect Housekeeping Tools`,
        route: PAGE_ROUTES.aiSkillHousekeeping,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'skills/knowledge',
        name: <Trans>Knowledge</Trans>,
        description: <Trans>Feed AI Agent with information</Trans>,
        route: `${PAGE_ROUTES.aiSkillKnowledge}`,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'skills/recommendations',
        name: <Trans>Recommendations</Trans>,
        description: <Trans>Give AI personalized suggestions</Trans>,
        route: `${PAGE_ROUTES.aiSkillRecommendations}`,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'skills/pms',
        name: <Trans>PMS</Trans>,
        description: <Trans>Sync your PMS to the AI bot</Trans>,
        route: `${PAGE_ROUTES.aiSkillPMS}`,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'skills/leadGeneration',
        name: <Trans>Lead generation</Trans>,
        description: <Trans> Capture potential interest</Trans>,
        route: `${PAGE_ROUTES.aiSkillLeadGen}`,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'skills/connectToHuman',
        name: <Trans>Connect To Human</Trans>,
        description: <Trans>Reach Human Help</Trans>,
        route: `${PAGE_ROUTES.aiSkillConnectToHuman}`,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'skills/config',
        name: <Trans>Configuration</Trans>,
        description: <Trans>Configuration</Trans>,
        route: `${PAGE_ROUTES.aiSkillConfig}`,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
    ],
  },
  {
    id: 'channels',
    name: <Trans>Channels</Trans>,
    route: PAGE_ROUTES.channelWebsite,
    baseRoute: PAGE_ROUTES.channels,
    query: {},
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: false,
    hasSubMenu: true,
    icon: ICONS.channels,
    subMenu: [
      {
        id: 'channels/website',
        name: <Trans>Website chatbot</Trans>,
        description: null,
        route: PAGE_ROUTES.channelWebsite,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'channels/whatsapp',
        name: <Trans>WhatsApp</Trans>,
        description: null,
        route: PAGE_ROUTES.channelWhatsApp,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'channels/sms',
        name: <Trans>SMS</Trans>,
        description: null,
        route: PAGE_ROUTES.channelSms,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'channels/email',
        name: <Trans>Email</Trans>,
        description: null,
        route: PAGE_ROUTES.channelEmail,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'channels/facebook',
        name: <Trans>Facebook</Trans>,
        description: null,
        route: PAGE_ROUTES.channelFacebook,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'channels/instagram',
        name: <Trans>Instagram</Trans>,
        description: null,
        route: PAGE_ROUTES.channelInstagram,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'channels/booking-com',
        name: <Trans>Booking.com</Trans>,
        description: null,
        route: PAGE_ROUTES.channelBookingCom,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'channels/freshchat',
        name: <Trans>Freshchat</Trans>,
        description: null,
        route: PAGE_ROUTES.channelFreshchat,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      // {
      //   id: 'channels/slack',
      //   name: <Trans>Slack</Trans>,
      //   description: null,
      //   route: PAGE_ROUTES.channelSlack,
      //   query: {},
      //   collapsesOnNavigate: false,
      //   hidden: false,
      //   isExternalLink: false,
      // },
    ],
  },
  {
    id: 'inventory',
    name: <Trans>Inventory</Trans>,
    route: PAGE_ROUTES.inventoryBookingEngine,
    query: {},
    baseRoute: PAGE_ROUTES.inventory,
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: false,
    hasSubMenu: true,
    icon: ICONS.inventory,
    subMenu: [
      {
        id: 'inventory/bookingEngine',
        name: <Trans>Booking Engine</Trans>,
        description: t`Booking Engine`,
        route: PAGE_ROUTES.inventoryBookingEngine,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'inventory/experience',
        name: <Trans>Experiences</Trans>,
        description: t`Experiences`,
        route: PAGE_ROUTES.inventoryExperiences,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'inventory/calendar',
        name: <Trans>Calendar</Trans>,
        description: t`Calendar`,
        route: PAGE_ROUTES.inventoryCalendar,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'inventory/payouts',
        name: <Trans>Payouts</Trans>,
        description: t`Payouts`,
        route: PAGE_ROUTES.inventoryPayouts,
        query: { tabId: 'stripe' },
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
    ],
  },
  {
    id: 'customers',
    name: <Trans>Customers</Trans>,
    route: PAGE_ROUTES.customers,
    baseRoute: PAGE_ROUTES.customers,
    query: {},
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: false,
    icon: ICONS.customer,
  },
  {
    id: 'admin',
    name: <Trans>Admin</Trans>,
    route: PAGE_ROUTES.admin,
    baseRoute: PAGE_ROUTES.admin,
    query: {},
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: false,
    onlyAdmin: true,
    icon: ICONS.admin,
  },
];

export const MENU_FOOTER_LIST = [
  {
    id: 'help',
    name: <Trans>Help</Trans>,
    route: 'https://help.d3x.ai/',
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: true,
    icon: ICONS.help,
  },
  {
    id: 'settings',
    name: <Trans>Settings</Trans>,
    route: PAGE_ROUTES.settings,
    query: {},
    baseRoute: PAGE_ROUTES.settings,
    collapsesOnNavigate: false,
    hidden: false,
    isExternalLink: false,
    hasSubMenu: true,
    icon: ICONS.settings,
    subMenu: [
      {
        id: 'settings',
        name: <Trans>Basic information</Trans>,
        route: PAGE_ROUTES.settings,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'settings/team',
        name: <Trans>Team</Trans>,
        route: PAGE_ROUTES.settingsTeam,
        query: {},
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'settings/integrations',
        name: <Trans>Integrations</Trans>,
        route: PAGE_ROUTES.settingsIntegrations,
        query: { tabId: 'pms' },
        collapsesOnNavigate: false,
        hidden: false,
        isExternalLink: false,
      },
      {
        id: 'settings/admin',
        name: <Trans>Admin</Trans>,
        route: PAGE_ROUTES.settingsAdmin,
        query: {},
        collapsesOnNavigate: false,
        hidden: true,
        isExternalLink: false,
      },
    ],
  },
];

export const USER_MENU_LIST = [
  {
    id: 'profile',
    name: <Trans>User Profile</Trans>,
    route: PAGE_ROUTES.profile,
    baseRoute: PAGE_ROUTES.profile,
    query: {},
    isExternalLink: false,
  },
];

export const GROUP_MENU_BLACK_LIST = ['customers'];

export const GROUP_SUBMENU_BLACK_LIST = [
  'skills/housekeeping',
  'skills/pms',
  'channels/whatsapp',
  'channels/sms',
  'channels/facebook',
  'channels/instagram',
  'channels/booking-com',
];

export default [...MENU_HEADER_LIST, ...MENU_FOOTER_LIST];
