import Head from 'next/head';

export default function PageTitle({ pageName }) {
  const pageTitle = `${pageName} - D3x`;
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content="d3x" />
    </Head>
  );
}
