import { createStyles } from '@mantine/core';
import ChevronDown from '@akin/ui-lib/svg/ChevronDown';
import { UserButton } from '@akin/ui-lib';
import { getSpaceProfileImageURL } from '@akin/core-lib/utils/spaceProfile';
import useSpace from '../../../../hooks/useSpace';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
  menuTarget: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 5,
    '& .spaceName': {
      flex: 1,
    },
    '&.disableHover:hover': {
      backgroundColor: 'unset',
      cursor: 'default',
    },
  },
  menuDropDown: {
    padding: 0,
    backgroundColor: theme.black,
    minWidth: '200px !important',
  },
  menuItem: {
    padding: 0,
    borderRadius: 0,
    marginBottom: 2,
    '&[data-hovered]': {
      backgroundColor: theme.colors.gray[9],
    },
    '&.selected': {
      backgroundColor: theme.colors.gray[8],
    },
  },
  chevron: {
    transform: 'unset',
    width: 13,
  },
  spaceButton: {
    paddingLeft: 0,
  },
}));

export default function MobileSpaceMenuButton({ onClick, isSuperAdmin }) {
  const { classes, cx } = useStyles();

  const { spaces, currentSpace } = useSpace();
  const spaceCount = Object.keys(spaces).length || 0;

  if (!spaces || Object.keys(spaces).length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div
        className={cx(classes.menuTarget, {
          disableHover: spaceCount === 1 && !isSuperAdmin,
        })}
        title={currentSpace?.name}
        {...((spaceCount > 1 || isSuperAdmin) && { onClick })}
        aria-hidden="true"
      >
        <UserButton
          name={currentSpace?.name}
          src={getSpaceProfileImageURL(currentSpace)}
          disableHover
          avatarProps={{ size: 30, color: 'dark' }}
          titleProps={{ weight: '600', color: isSuperAdmin ? '#fff' : 'dark' }}
          className={classes.spaceButton}
        />

        {(spaceCount > 1 || isSuperAdmin) && (
          <ChevronDown
            stroke={isSuperAdmin ? '#fff' : '#000'}
            width={15}
            height={15}
            className={cx(classes.chevron)}
          />
        )}
      </div>
    </div>
  );
}
