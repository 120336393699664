import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { processNavMenuList } from './helper';
import useSpaceMenu from '../../../../hooks/useSpaceMenu';

export default function TopMenuList({ isMobileScreen }) {
  const router = useRouter();
  const { getHeaderMenuList } = useSpaceMenu();

  const navItems = useMemo(() => {
    const menuList = getHeaderMenuList();

    const navItemsList = processNavMenuList({
      menuList,
      currentRoute: router.asPath,
      isMobileScreen,
    });

    return navItemsList;
  }, [router.asPath, isMobileScreen, getHeaderMenuList]);

  return navItems.map((menu) => {
    const NavComponent = menu.component;
    return <NavComponent {...menu.props} />;
  });
}
