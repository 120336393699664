import AuthLayout from './AuthLayout';
import MainLayout from './MainLayout';

export function getAuthLayout(page) {
  return <AuthLayout>{page}</AuthLayout>;
}

export function getMainLayout(page) {
  return <MainLayout>{page}</MainLayout>;
}
