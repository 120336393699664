import http from './axiosConfig';
import APIError from './customErrors';

export const createSpace = async (data) => {
  try {
    const response = await http.post(`/superAdmin/space`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const searchSpaces = async (queryParams) => {
  try {
    const response = await http.get(`/superAdmin/space/search`, {
      withCredentials: true,
      params: { ...queryParams },
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};
