import { useCallback, useEffect, useState } from 'react';
import intersection from 'lodash/intersection';
import useSpaceSettings from '../../hooks/useSpaceSettings';
import { MODULE_PERMISSIONS } from './constants';

export default function usePermissions(props = {}) {
  const { moduleName = '' } = props;
  const { userSpacePermissions } = useSpaceSettings();
  const [hasAccess, setHasAccess] = useState(false);

  const getModuleAccessStatus = useCallback(
    (module) => {
      if (!MODULE_PERMISSIONS?.[module]) return false;

      const modulePermissions = MODULE_PERMISSIONS[module];
      const result = intersection(modulePermissions, userSpacePermissions);
      return result.length > 0;
    },
    [userSpacePermissions]
  );

  useEffect(() => {
    if (moduleName && userSpacePermissions?.length > 0) {
      const status = getModuleAccessStatus(moduleName);
      setHasAccess(status);
    }
  }, [moduleName, userSpacePermissions]);

  return { hasAccess, getModuleAccessStatus };
}
