import Link from 'next/link';
import { Image, Text } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  navItem: {
    backgroundColor: theme.black,
    color: '#5f5f5f',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: 12,
    lineHeight: '1.5',
    cursor: 'pointer',
    textDecoration: 'none',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: theme.other.boxShadow.cards,
    '& img': {
      filter: 'invert(60%)',
    },
    '&:hover, &.selected': {
      backgroundColor: '#212121',
      color: theme.white,
      '& img': {
        filter: 'brightness(0) invert(1)',
      },
    },
    [theme.fn.smallerThan('xs')]: {
      backgroundColor: 'transparent',
      color: '#6D6B6B',
      boxShadow: 'none',
      justifyContent: 'start',
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover, &.selected': {
        backgroundColor: '#212121',
        color: theme.white,
      },
    },
  },
  image: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },
  label: {
    whiteSpace: 'pre-wrap',
    fontSize: 10,
    marginTop: 4,
    [theme.fn.smallerThan('xs')]: {
      fontSize: 14,
      marginTop: 0,
    },
  },
}));

export default function NavItem({
  title,
  isSelectedMenu,
  href,
  onClick,
  icon,
  hidden = false,
}) {
  const { classes, cx } = useStyles();

  if (hidden) return null;

  return (
    <Link className={classes.link} href={href}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <div
        className={cx(classes.navItem, {
          selected: isSelectedMenu,
        })}
        onClick={onClick}
        aria-hidden="true"
      >
        <Image src={icon} width={19} height={19} className={classes.image} />
        <Text ta="center" weight={600} className={classes.label}>
          {title}
        </Text>
      </div>
    </Link>
  );
}
