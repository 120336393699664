export function getUserImageUrl(user) {
  if (!user?.profile_pic_key) return null;
  return `${user.prefix}${user.profile_pic_key}`;
}

export function validateProfileOwner(user, currentUserId) {
  if (!currentUserId || !user?.user_id) return false;
  if (user.user_id === currentUserId) return true;
  return false;
}

export function getCoverImageUrl(userProfile) {
  if (!userProfile?.cover_pic_key) return null;
  return `${userProfile.prefix}${userProfile.cover_pic_key}`;
}
