import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { MENU_FOOTER_LIST } from '../../../../constants/menu';
import { processNavMenuList } from './helper';
import usePermissions from '../../../../features/permissions/usePermissions';

export default function FooterMenuList({ isMobileScreen }) {
  const router = useRouter();
  const { getModuleAccessStatus } = usePermissions();

  const navItems = useMemo(() => {
    const menuList = [];

    MENU_FOOTER_LIST.forEach((menu) => {
      const hasAccess = getModuleAccessStatus(menu.id);

      if (hasAccess) {
        const menuItem = { ...menu };

        if (menu.hasSubMenu) {
          menuItem.subMenu = menu.subMenu.filter((subMenu) =>
            getModuleAccessStatus(subMenu.id)
          );
        }

        menuList.push({ ...menuItem });
      }
    });

    const items = processNavMenuList({
      menuList,
      currentRoute: router.pathname,
      isMobileScreen,
    });

    return items;
  }, [router.pathname, isMobileScreen, getModuleAccessStatus]);

  return navItems.map((menu) => {
    const NavComponent = menu.component;
    return <NavComponent {...menu.props} />;
  });
}
