import { store } from '../../store';

export function getPlatformPageRoute(path, query = {}) {
  try {
    return {
      pathname: path,
      query,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function getSpacePageRoute(path, query = {}) {
  try {
    const { spaceSettings, spaces } = store.getState();
    const currentSpace = spaces?.[spaceSettings?.currentSpaceId] || {};
    return {
      pathname: path,
      query: {
        spaceCode: currentSpace.space_code,
        ...query,
      },
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
