import {
  GROUP_SUBMENU_BLACK_LIST,
  MENU_HEADER_LIST,
  GROUP_MENU_BLACK_LIST,
} from '../constants/menu';
import usePermissions from '../features/permissions/usePermissions';
import useSpaceSettings from './useSpaceSettings';

export default function useSpaceMenu() {
  const { spaceSettings } = useSpaceSettings();
  const { getModuleAccessStatus } = usePermissions();

  const getSubMenuList = (listData) => {
    // Check if user has access to the sub menu...
    let subMenuList = listData.filter((subMenu) =>
      getModuleAccessStatus(subMenu.id)
    );

    // Filter submenu which does not exist Group space...
    if (spaceSettings?.isGroup) {
      subMenuList = subMenuList.filter(
        (subMenu) => !GROUP_SUBMENU_BLACK_LIST.includes(subMenu.id)
      );
    }

    return [...subMenuList];
  };

  const getHeaderMenuList = () => {
    const menuList = [];

    MENU_HEADER_LIST.forEach((menu) => {
      // Check if user has access to the main menu...
      const hasAccess = getModuleAccessStatus(menu.id);

      if (hasAccess) {
        const menuItem = { ...menu };

        if (spaceSettings?.isGroup && GROUP_MENU_BLACK_LIST.includes(menu.id)) {
          return;
        }

        // Check if menu has a sub menu...
        if (menu.hasSubMenu) {
          menuItem.subMenu = getSubMenuList(menu.subMenu);
        }

        menuList.push({ ...menuItem });
      }
    });

    return [...menuList];
  };

  return {
    getHeaderMenuList,
    getSubMenuList,
  };
}
