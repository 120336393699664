import { createStyles } from '@mantine/core';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { processSubMenuList } from './helper';
import useSpaceMenu from '../../../../hooks/useSpaceMenu';

const useStyles = createStyles((theme) => ({
  root: {
    width: 227,
    height: '100%',
    backgroundColor: '#212121',
    overflowY: 'auto',
    ...theme.other.hideScrollBar,
    '&.hide': {
      display: 'none',
      transition: ' opacity 1s ease-out',
    },
  },
  item: {
    padding: '0.8rem 1rem',
  },
}));

export default function SubMenu({ listData = [] }) {
  const { cx, classes } = useStyles();
  const router = useRouter();
  const { getSubMenuList } = useSpaceMenu();

  const subMenuList = useMemo(() => {
    const subMenuItems = getSubMenuList(listData);

    return processSubMenuList({
      menuList: subMenuItems,
      currentRoute: router.pathname,
    });
  }, [listData, router.pathname]);

  return (
    <div className={cx(classes.root, { hide: subMenuList?.length === 0 })}>
      {subMenuList.map((menu) => {
        const NavComponent = menu.component;
        return <NavComponent {...menu.props} />;
      })}
    </div>
  );
}
